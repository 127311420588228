/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "./theme/variables.scss";

.ion-text-underline {
    text-decoration: underline;
}

p.ion-color-primary {
    color: var(--ion-color-primary);
}

ion-item[hidden],
ion-card[hidden],
ion-list[hidden],
.hidden {
    display: none !important;
}

.ion-background-color {
    background-color: var(--ion-background-color);
}

.ion-background-color-primary {
    background-color: var(--ion-color-primary);
}

.ion-background-color-secondary {
    background-color: var(--ion-color-secondary);
}

.ion-background-color-tertiary {
    background-color: var(--ion-color-tertiary);
}

.ion-background-color-success {
    background-color: var(--ion-color-success);
}

.ion-background-color-warning {
    background-color: var(--ion-color-warning);
}

.ion-background-color-danger {
    background-color: var(--ion-color-danger);
}

.ion-background-color-dark {
    background-color: var(--ion-color-dark);
}

.ion-background-color-medium {
    background-color: var(--ion-color-medium);
}

.ion-background-color-light {
    background-color: var(--ion-color-light);
}

app-vertical-range {
    display: flex;
}

.label-justify {
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
}

.form-item {
    color: var(--ion-card-form-item);
    margin-bottom: 1.5rem;
    ion-input,
    ion-textarea,
    ion-select {
        border: .1rem solid;
        border-radius: 6px;
        margin: 8px 0;
        --padding: 3px 8px;
        --padding-start: 12px;
        --padding-end: 12px;
    }
}

.speaker-photo {
    width: 100px;
    height: 100px;
    padding: 8px;
}

.speaker-photo::part(image) {
    border-radius: 50%;
}

ion-item-group.item-separate,
ion-list.item-separate {
    ion-item-divider {
        background: none;
        color: var(--ion-color-dark);
        h1 {
            font-weight: bold;
        }
        h2 {
            color: var(--ion-color-medium);
        }
        h3 {
            color: var(--ion-color-medium-shade);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
        }
    }
    background: none;
    ion-list-header {
        background: none;
    }
    ion-item {
        margin: 15px 15px;
        box-shadow: 0px 2px 15px 0px var(--ion-item-box-shadow);
        border-radius: 25px;
        border-right: 20px solid var(--ion-color-medium);
        ion-label {
            p {
                font-weight: bold;
            }
        }
    }
    ion-item::part(native) {
        padding: 10px 0px;
    }
    .avatar-wrapper {
        margin-inline: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: flex;
        flex-flow: column;
        gap: 5px;
        position: relative;
        ion-avatar {
            width: 55px;
            height: 55px;
        }
        ion-badge {
            position: absolute;
            right: -10px;
            bottom: -10px;
        }
    }
    ion-avatar.icon {
        width: 55px;
        height: 55px;
        background: var(--ion-avatar-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        ion-img {
            border-radius: 0;
        }
        ion-img::part(image) {
            object-fit: scale-down;
        }
    }
    ion-icon {
        background: var(--ion-icon-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        font-size: 29px;
        margin-top: 2px;
        margin-bottom: 2px;
        color: var(--ion-color-dark);
    }
    ion-img {
        max-width: 30px;
        height: auto;
    }
    ion-badge {
        font-size: 1rem;
        color: var(--ion-color-medium);
        --background: var(--ion-badge-background);
    }
    .ion-item-date {
        margin-inline-start: 15px;
        margin-inline-end: 0px;
    }
}

.item-separate.item-radios {
    ion-radio {
        margin-inline: 15px;
    }
}

ion-menu ion-avatar {
    width: 80px;
    height: 80px;
    background: var(--ion-color-light);
    padding: 8px;
    ion-img {
        padding: 5px;
    }
    ion-img::part(image) {
        object-fit: scale-down;
    }
}

@media (prefers-color-scheme: dark) {
    .dark-mode-image {
        src: url('./assets/imgs/lbc-logo.svg');
    }
}

ion-card.item-separate {
    border-radius: 25px;
    padding: 0;
    ion-card-content {
        padding-inline: 0;
        padding: 6px 0;
        ion-list {
            ion-item {
                --padding-start: 0;
                margin: 5px 0;
                ion-icon {
                    background: var(--ion-icon-background);
                    padding: 13px;
                    margin-inline: 15px;
                    border-radius: 35%;
                    font-size: 18px;
                    // margin-top: 2px;
                    // margin-bottom: 2px;
                    color: var(--ion-color-dark);
                }
            }
        }
    }
}

ion-card-header {
    flex-direction: column;
}

// ion-card-content ion-list ion-item::part(native) {
//     border-bottom: .55px solid var(--ion-color-primary-shade);
// }

ion-card.ion-accordion {
    ion-card-title {
        font-size: 16px;
        color: var(--ion-color-medium);
    }
}

ion-accordion {
    ion-item {
        margin: 10px 15px;
    }
    ion-item::part(native) {
        background: var(--ion-item-background);
        border-radius: 15px;
        padding: 10px;
    }
}

.rotate-icon {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
}

.rotate-icon.rotate {
    animation: rotateIcon 0.3s forwards;
}

@keyframes rotateIcon {
    0% {
        transform: rotateX(0);
    }
    100% {
        transform: rotateX(-180deg);
    }
}

.ion-align-items-center-page {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    strong {
        font-size: 20px;
        line-height: 26px;
    }
    p {
        font-size: 16px;
        line-height: 22px;
        color: var(--ion-color-dark-tint);
    }
    a {
        text-decoration: none;
    }
}

.ion-border {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    border: .07rem solid;
    padding: 1.5rem .5rem;
}