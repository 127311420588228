// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
    /** primary **/
    --ion-color-primary: #3fcfd9;
    --ion-color-primary-rgb: 63, 207, 217;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #37b6bf;
    --ion-color-primary-tint: #52d4dd;
    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
    /** tertiary **/
    --ion-color-tertiary: #ffab1a;
    --ion-color-tertiary-rgb: 255, 171, 26;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #e09617;
    --ion-color-tertiary-tint: #ffb331;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    /** dark **/
    --ion-color-dark: #0d233d;
    --ion-color-dark-rgb: 13, 35, 61;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #081525;
    --ion-color-dark-tint: #184071;
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-background-color: #f2f5fa;
    --ion-item-background: #ffffff;
    --ion-item-box-shadow: #ced2db;
    --ion-badge-background: #f2f5fa;
    --ion-icon-background: #f2f5fa;
    --ion-tab-bar-background: #ffffff;
    --ion-tab-bar-border-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
     :root {
        --ion-background-color: #253252;
        --ion-background-color-rgb: 37, 50, 82;
        --ion-item-background: #30415f;
        --ion-item-box-shadow: #233050;
        --ion-badge-background: #2c3b5a;
        --ion-icon-background: #2c3b5a;
        --ion-card-background: #30415f;
        --ion-toolbar-background: #30415f;
        --ion-tab-bar-background: #30415f;
        --ion-tab-bar-border-color: #30415f;
        --ion-toolbar-border-color: #30415f;
        --ion-avatar-background: #2c3b5a;
    }
    /*
   * Dark Colors
   * -------------------------------------------
   */
    body {
        --ion-color-primary: #3fcfd9;
        --ion-color-primary-rgb: 63, 207, 217;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0, 0, 0;
        --ion-color-primary-shade: #37b6bf;
        --ion-color-primary-tint: #52d4dd;
        --ion-color-secondary: #3dc2ff;
        --ion-color-secondary-rgb: 61, 194, 255;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #36abe0;
        --ion-color-secondary-tint: #50c8ff;
        --ion-color-tertiary: #ffab1a;
        --ion-color-tertiary-rgb: 255, 171, 26;
        --ion-color-tertiary-contrast: #000000;
        --ion-color-tertiary-contrast-rgb: 0, 0, 0;
        --ion-color-tertiary-shade: #e09617;
        --ion-color-tertiary-tint: #ffb331;
        --ion-color-success: #2fdf75;
        --ion-color-success-rgb: 47, 223, 117;
        --ion-color-success-contrast: #000000;
        --ion-color-success-contrast-rgb: 0, 0, 0;
        --ion-color-success-shade: #29c467;
        --ion-color-success-tint: #44e283;
        --ion-color-warning: #ffd534;
        --ion-color-warning-rgb: 255, 213, 52;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0bb2e;
        --ion-color-warning-tint: #ffd948;
        --ion-color-danger: #ff4961;
        --ion-color-danger-rgb: 255, 73, 97;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #e04055;
        --ion-color-danger-tint: #ff5b71;
        --ion-color-dark: #f4f5f8;
        --ion-color-dark-rgb: 244, 245, 248;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #d7d8da;
        --ion-color-dark-tint: #f5f6f9;
        --ion-color-medium: #989aa2;
        --ion-color-medium-rgb: 152, 154, 162;
        --ion-color-medium-contrast: #000000;
        --ion-color-medium-contrast-rgb: 0, 0, 0;
        --ion-color-medium-shade: #86888f;
        --ion-color-medium-tint: #a2a4ab;
        --ion-color-light: #0d233d;
        --ion-color-light-rgb: 13, 35, 61;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #081525;
        --ion-color-light-tint: #184071;
    }
    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    .ios body {
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-color-step-50: #0d233d;
        --ion-color-step-100: #153d65;
        --ion-color-step-150: #16406a;
        --ion-color-step-200: #134779;
        --ion-color-step-250: #13497c;
        --ion-color-step-300: #125392;
        --ion-color-step-350: #135496;
        --ion-color-step-400: #1567b4;
        --ion-color-step-450: #1569b7;
        --ion-color-step-500: #2384d4;
        --ion-color-step-550: #2386d7;
        --ion-color-step-600: #4aa0e6;
        --ion-color-step-650: #50a3e7;
        --ion-color-step-700: #89c0f0;
        --ion-color-step-750: #8ec3f0;
        --ion-color-step-800: #c0dcf7;
        --ion-color-step-850: #c4dff7;
        --ion-color-step-900: #e3eefb;
        --ion-color-step-950: #f1f7fe;
    }
    .ios ion-modal {
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }
    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    .md body {
        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;
        --ion-border-color: #222222;
        --ion-color-step-50: #0d233d;
        --ion-color-step-100: #153d65;
        --ion-color-step-150: #16406a;
        --ion-color-step-200: #134779;
        --ion-color-step-250: #13497c;
        --ion-color-step-300: #125392;
        --ion-color-step-350: #135496;
        --ion-color-step-400: #1567b4;
        --ion-color-step-450: #1569b7;
        --ion-color-step-500: #2384d4;
        --ion-color-step-550: #2386d7;
        --ion-color-step-600: #4aa0e6;
        --ion-color-step-650: #50a3e7;
        --ion-color-step-700: #89c0f0;
        --ion-color-step-750: #8ec3f0;
        --ion-color-step-800: #c0dcf7;
        --ion-color-step-850: #c4dff7;
        --ion-color-step-900: #e3eefb;
        --ion-color-step-950: #f1f7fe;
    }
}